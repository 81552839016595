<script setup lang="ts">
import { ValidationObserver } from 'vee-validate'
import { computed, ref, onBeforeMount, nextTick } from 'vue'
import { useRouter } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseMetafield from '/~/components/base/metafield/base-metafield.vue'
import DrawerMethodTile from '/~/components/drawer/components/tiles/drawer-method-tile.vue'
import { useAnalytics } from '/~/composables/analytics'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'
import { useCms } from '/~/composables/cms/use-cms'
import { useLocalization } from '/~/composables/localization'
import {
  usePadAgreement,
  usePaymentMethods,
} from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useBankAccountForm } from '/~/composables/payment-methods/use-bank-account-form'
import { useUser } from '/~/composables/user'

const emit = defineEmits<{
  (event: 'submit', payload: unknown): void
}>()

const { backendErrors, processBackendErrors } = useBackendValidation()
const { bankAccountSchema, accountPresentation, form, initiateValues } =
  useBankAccountForm()
const { createBankAccount } = usePaymentMethods()
const { isDarkThemeForEwallet } = useCms()
const { trackBankFormAnalytics } = useAnalytics()
const { user } = useUser()
const router = useRouter()
const { validationObserverRef } = useForm()
const { isPadEnabled, padTemplate, padDictionary, onPadInit } =
  usePadAgreement()
const { translate } = useLocalization()

const bankAccountForm = ref(null)
const tocAccepted = ref(false)
const submitting = ref(false)

const accountPresentationForm = computed(() => {
  const accountFields = Object.keys(accountPresentation).reduce(
    (acc: any, key: any) => {
      acc[key] = (form.value as any)[(accountPresentation as any)[key]]
      return acc
    },
    {}
  )

  return {
    type: PaymentMethodType.bankAccount,
    ...accountFields,
  }
})

onBeforeMount(() => {
  form.value.accountName = user.value.fullName

  if (form.value.accountName) {
    nextTick(() => {
      ;(bankAccountForm.value as any)?.$refs['accountName'][0].validate(
        form.value.accountName
      )
    })
  }
})

function onCreated() {
  form.value = { ...initiateValues }
  tocAccepted.value = false

  trackBankFormAnalytics({ label: 'Bank account added' })
}

function onCancelForm() {
  router.back()
}

const canProceed = computed(() => {
  return (
    !submitting.value &&
    Object.keys(backendErrors.value).length === 0 &&
    tocAccepted.value
  )
})

async function onSubmit() {
  if (isPadEnabled.value) {
    onPadInit(form.value)
    bottomSheet.show('bank-account-pad', {
      to: 'menu-modal',
      props: {
        dictionary: padDictionary.value,
        htmlTemplate: padTemplate.value,
        onSubmit: onSubmitForm,
      },
      style: {
        fullheight: true,
        animation: 'right',
      },
    })
  } else {
    onSubmitForm()
  }
}

async function onSubmitForm() {
  submitting.value = true

  try {
    const payload: any = { ...form.value }

    payload.termsAndConditions = tocAccepted.value

    const account = await createBankAccount(payload)

    onCreated()

    emit('submit', { id: account.id, type: 'db' })
  } catch (exception: any) {
    processBackendErrors(exception.data || exception)
  } finally {
    validationObserverRef.value?.reset()
    submitting.value = false
  }
}

function showTermsAndConditions() {
  bottomSheet.show('terms-conditions', { to: 'menu-modal' })
}
</script>

<template>
  <div
    class="relative flex flex-grow flex-col items-center justify-between"
    :style="{
      '--color-text': isDarkThemeForEwallet ? '#FFFFFF' : null,
    }"
  >
    <div class="mb-10 w-full">
      <drawer-method-tile :method="accountPresentationForm" />
    </div>
    <validation-observer
      v-slot="{ valid, pending }"
      ref="validationObserverRef"
      tag="form"
      class="flex w-full grow flex-col"
      @submit.prevent="onSubmit"
    >
      <div class="w-full grow overflow-y-auto">
        <div class="mx-auto max-w-[578px]">
          <base-metafield
            ref="bankAccountForm"
            v-model="form"
            :submitting="submitting"
            :schema="bankAccountSchema"
            :backend-errors="backendErrors"
          />
        </div>
      </div>
      <div class="sticky bottom-0 -mx-5 w-auto border-t bg-white px-5 pb-5">
        <div class="grid w-full grid-cols-2 gap-x-5">
          <div class="col-span-2 py-5">
            <base-checkbox
              v-model="tocAccepted"
              v-analytics:input="
                (e) => ({
                  pageGroup: 'Drawer',
                  page: 'Add payment method',
                  component1: 'Bank account',
                  label: `${e.target.checked ? 'Tick' : 'Untick'} Agree to T&C`,
                })
              "
              :disabled="submitting"
              look="v2"
            >
              <span class="font-normal text-default">
                I agree to
                <base-action
                  v-analytics:click="{
                    pageGroup: 'Drawer',
                    page: 'Add payment method',
                    component1: 'Bank account',
                    cta: 'Terms & Conditions',
                  }"
                  class="font-semibold text-primary underline"
                  type="link"
                  :click-only="true"
                  @click="showTermsAndConditions"
                >
                  Terms {{ translate('common.and') }} Conditions
                </base-action>
              </span>
            </base-checkbox>
          </div>
          <base-button
            v-analytics:click="{
              pageGroup: 'Drawer',
              page: 'Add payment method',
              component1: 'Bank account',
              cta: 'Cancel',
            }"
            class="w-full"
            look="outlined-color"
            @click="onCancelForm"
          >
            Cancel
          </base-button>
          <base-button
            v-analytics:click="{
              pageGroup: 'Drawer',
              page: 'Add payment method',
              component1: 'Bank account',
              cta: 'Confirm',
            }"
            class="w-full"
            :disabled="!canProceed || !valid || pending"
            :loading="submitting"
            type="submit"
          >
            {{ submitting ? 'Confirming...' : 'Confirm' }}
          </base-button>
        </div>
      </div>
    </validation-observer>
  </div>
</template>
