<script setup lang="ts">
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms/use-cms'

const { cmsContent } = useCms()
</script>

<template>
  <div v-if="cmsContent?.paymentMethods.addCardNotificationEnabled">
    <div
      class="flex items-center space-x-2 rounded p-2 text-sm"
      :style="{
        'background-color':
          cmsContent?.paymentMethods.addCardNotificationBgColor ??
          'bg-primary-light',
      }"
    >
      <base-icon
        :size="Number(cmsContent?.paymentMethods.addCardNotificationIconSize)"
        :svg="
          cmsContent?.paymentMethods.addCardNotificationIcon ??
          'heroicons/outline/information-circle'
        "
        class="flex-shrink-0"
        :style="{
          color: cmsContent?.paymentMethods.addCardNotificationIconColor,
        }"
      />
      <div
        :style="{
          color: cmsContent?.paymentMethods.addCardNotificationTextColor,
        }"
        v-html="cmsContent?.paymentMethods.addCardNotificationText"
      ></div>
    </div>
  </div>
</template>
